import React, {useEffect, useState} from "react";
import {t} from "../../translations/Translation";
import DatePicker from "react-multi-date-picker"
import Button from 'react-bootstrap/Button';
import FilterDropdown from "./FilterDropdown";
import { APICALL as AXIOS } from "../../services/AxiosServices";
import { GetHolidayCodes } from "../../routes/ApiEndPoints";
import { GetFormattedDate, GetReversedDate } from "../../utilities/CommonFunctions";

export default function AddLeaveForm(props){
    const [datesArray, setDatesArray] = useState([]);
    const [date, setDate] = useState(props.updateLeaveData.length === 0 ? '': props.updateLeaveData.leave_from_date );
    const [toDate, setToDate] = useState(props.updateLeaveData.length === 0 && props.updateLeaveData.multiple_days === false ? '' : props.updateLeaveData.leave_to_date );

    const [selectedEmployee, setSelectedEmployee] = useState(props.updateLeaveData.length === 0 ? '': props.updateLeaveData.employee);
    const [singleHolidayCode, setSingleHolidayCode] = useState(props.updateLeaveData.length !== 0 && (!props.updateLeaveData.multiple_holiday_codes)? props.updateLeaveData.holiday_code_details : "");
    const [holidayCode, setHolidayCode] = useState([]);
    const [holidayCodesList, setHolidayCodesList] = useState([]);
    const [hoursHolidayCodesList, setHoursHolidayCodesList] = useState([]);

    const [hoursRow, setHoursRow] = useState(props.updateLeaveData.length === 0 && (!props.updateLeaveData.multiple_holiday_codes) ? [1] : props.updateLeaveData.holiday_code_details)

    const [multipleStatus, setMultiplestatus] = useState(props.updateLeaveData.length === 0 ? false : props.updateLeaveData.multiple_days);
    const [calendarSelect, setCalendarSelect] = useState(false);
    const [holidayCodeStatus, setHolidayCodeStatus] = useState(props.updateLeaveData.length === 0 ? false : props.updateLeaveData.multiple_holiday_codes);
    const [halfDayStatus, setHalfDayStatus] = useState(props.updateLeaveData.length === 0 ? false : props.updateLeaveData.half_day);

    const [reason, setReason] = useState(props.updateLeaveData.length !== 0 ? props.updateLeaveData.reason : '')
    const [hours, setHours] = useState('');

    useEffect(()=>{
        if (props.updateLeaveData.length !== 0 && multipleStatus !== props.updateLeaveData.multiple_days){
            setDate('');
            setDatesArray([]);
            setToDate('');
            setHolidayCodeStatus(false);
            setHolidayCode('')
            setHoursRow([1])
            props.leaveData['dates'] = {}
        }
        if (props.updateLeaveData === undefined || props.updateLeaveData.length === 0){
            setDate('');
            setDatesArray([]);
            setToDate('');
            setHolidayCodeStatus(false);
            setHolidayCode('')
            setHoursRow([1])
            props.leaveData['dates'] = {}
        }
    }, [multipleStatus, calendarSelect])

    useEffect(() =>{
        if (hoursRow.length < 1){
            setHoursRow([...hoursRow, 1]);
        }
    }, [holidayCodeStatus, holidayCode])

    useEffect(() => {
        if (props.updateLeaveData.length !== 0){
            GetHolidayCodesByEmployee(selectedEmployee.value)
            SetLeaveData(0, props.updateLeaveData.employee.value, 0);
            SetLeaveData(1, props.updateLeaveData.leave_from_date, 0);
            if (props.updateLeaveData.multiple_days){
                SetLeaveData(2, props.updateLeaveData.leave_to_date, 0);
            }
            if (props.updateLeaveData.multiple_holiday_codes){
                let selectedHolidayCodes = props.updateLeaveData.holiday_code_details
                selectedHolidayCodes.map((val, key) => {
                    SetLeaveData(5, val.hours, key);
                    SetLeaveData(6, val.holiday_code.value, key);
                })
            }
            else{
                SetLeaveData(3, props.updateLeaveData.holiday_code_details[0].holiday_code.value, 0);

            }
            SetLeaveData(4, props.updateLeaveData.reason, 0);
        }
    }, [])

    const AddMultipleHolidayCodes = () =>{
        setHoursRow([...hoursRow, 1]);
        setHours('')
    }

    const RemoveMultipleHolidayCodes = () =>{
        const rows = [...hoursRow];
        rows.splice(hoursRow.length-1, 1);
        setHoursRow(rows);

        if (hoursRow.length === holidayCode.length){
            const dataRow = [...holidayCode];
            dataRow.splice(holidayCode.length-1, 1);
            setHolidayCode(dataRow);
            props.leaveData['holiday_code'] = dataRow;
        }
    }

    const GetHolidayCodesByEmployee = (emp_id) =>{
        // setSelectedEmployee(emp_id);
        let data = {
            "lid": JSON.parse(localStorage.getItem('location')).value,
            "eid": emp_id,
            "token": localStorage.getItem("token")
        }
        AXIOS.service(GetHolidayCodes, "POST", data)
        .then((result)=>{
            if (result && result.status === 200 ){
                setHolidayCodesList(result.data);
                // setHolidayCodesList(result.data.day_codes);
                setHoursHolidayCodesList(result.data.hour_codes);
            }else{
                setHolidayCodesList([])
                setHoursHolidayCodesList([])
            }
        })
        .catch((error)=>{

        })
    }

    const SetLeaveData = (type, data, index) => {
        switch(type){
            case 0:
                GetHolidayCodesByEmployee(data);
                props.leaveData['eid'] = data;
                break;
            case 1:
                if (props.leaveData['dates'] === undefined){props.leaveData['dates'] = {}}
                if (multipleStatus && !calendarSelect) {
                    props.leaveData['dates']['from_date'] = (data ? (typeof(data) !== 'string' ? data.format("YYYY-MM-DD") : GetReversedDate(data)):'')
                    props.leaveData['status'] = 1
                    setDate(data ? (typeof(data) === 'string' ? data : data.format("DD-MM-YYYY")) : '')
                }else if (multipleStatus && calendarSelect){
                    let dates = {}
                    data.map((val, key) => {
                        dates[key] = val.format("YYYY-MM-DD")
                        setDatesArray([...datesArray, val.format("DD-MM-YYYY")])
                    })
                    props.leaveData['dates'] = dates
                    props.leaveData['status'] = 0
                }else{
                    props.leaveData['dates'] = data ? {'0': (typeof(data) !== 'string' ? data.format("YYYY-MM-DD") : GetReversedDate(data))} : {}
                    props.leaveData['status'] = 0
                    setDate(data ? (typeof(data) === 'string' ? data : data.format("DD-MM-YYYY")): '')
                }
                break;
            case 2:
                setToDate(data);
                props.leaveData['dates']['to_date'] = (data ? (typeof(data) !== 'string' ? data.format("YYYY-MM-DD") : GetReversedDate(data)):'')
                props.leaveData['status'] = 1
                break;
            case 3:
                props.leaveData['holiday_code'] = ({"0": {"holiday_code":data, "hours" : "0"}})
                props.leaveData['multiple_holiday_codes'] = 0
                setHolidayCode(props.leaveData['holiday_code'])
                break;
            case 4:
                props.leaveData['reason']=data;
                setReason(data)
                break;
            case 5:
                if (props.leaveData['holiday_code'] !== undefined){
                    if (typeof(props.leaveData['holiday_code']) === 'string') {props.leaveData['holiday_code'] = JSON.parse(props.leaveData['holiday_code'])}
                    if (props.leaveData['holiday_code'][index] !== undefined ){
                        props.leaveData['holiday_code'][index]['hours'] = data;
                        props.leaveData['multiple_holiday_codes'] = 1
                        setHolidayCode(props.leaveData['holiday_code'])
                        setHours(data)
                    } else{
                        props.leaveData['holiday_code'][index] = {}; 
                        props.leaveData['holiday_code'][index]['hours'] = data;
                        props.leaveData['multiple_holiday_codes'] = 1
                        setHolidayCode(props.leaveData['holiday_code'])
                        setHours(data)
                    }
                }else{
                    props.leaveData['holiday_code']= []
                    props.leaveData['holiday_code'][index]= {}
                    props.leaveData['holiday_code'][index]['hours'] = data
                    props.leaveData['multiple_holiday_codes'] = 1
                    setHolidayCode(props.leaveData['holiday_code'])
                    setHours(data)
                }
                break;
            case 6:
                if (props.leaveData['holiday_code'] !== undefined){
                    if (typeof(props.leaveData['holiday_code']) === 'string') {props.leaveData['holiday_code'] = JSON.parse(props.leaveData['holiday_code'])}
                    if (props.leaveData['holiday_code'][index] !== undefined ){
                        props.leaveData['holiday_code'][index]['holiday_code'] = data;
                        props.leaveData['multiple_holiday_codes'] = 1
                        setHolidayCode(props.leaveData['holiday_code'])
                    } else{
                        props.leaveData['holiday_code'][index] = {}; 
                        props.leaveData['holiday_code'][index]['holiday_code'] = data;
                        props.leaveData['multiple_holiday_codes'] = 1
                        setHolidayCode(props.leaveData['holiday_code'])
                    }
                }else{
                    props.leaveData['holiday_code'] = []
                    props.leaveData['holiday_code'][index]= {}
                    props.leaveData['holiday_code'][index]['holiday_code'] = data
                    props.leaveData['multiple_holiday_codes'] = 1
                    setHolidayCode(props.leaveData['holiday_code'])
                }
                break;
            case 7:
                props.leaveData['half_day']=data;
                setHalfDayStatus(!halfDayStatus);
                break;
        }
    }


    return(
        <form className="p-2 pl-3" >
            <div className="form-row col-12 pl-0 pt-3 planning-form float-left">
                <div className="col-4 title-position">
                    <FilterDropdown 
                        filterName={t("EMPLOYEE")}
                        options={props.names}
                        placeHolder={t("SELECT_EMPLOYEE_PLACEHOLDER")}
                        isMulti={false}
                        selectedOption={selectedEmployee}
                        setSelectedOption={(e) => SetLeaveData(0, e.value, 0)}
                        required={true}
                        isDisabled={props.updateLeaveData.length=== 0? true: false}
                    ></FilterDropdown>
                </div>
            </div>
            <div className="form-row col-12 pl-0 pt-1 planning-form float-left">
                <div className="col-4 title-position">
                    <label htmlFor="date" className="filter-title row m-0 mb-1">{multipleStatus ? (!calendarSelect ? t("FROM_DATE") : t("MULTIPLE_DATES")) : t('DATE')}<p className="text-danger mb-0 font-wieght-bold">&nbsp;*</p></label>
                    <DatePicker 
                        className="custom-calendar"
                        multiple={calendarSelect}
                        name="date"
                        value={calendarSelect ? datesArray : date} 
                        onChange={(date)=>SetLeaveData(1, date, 0)}
                        format="DD-MM-YYYY"
                        locale={localStorage.getItem('active_language')}
                    />
                </div>
                {multipleStatus && !calendarSelect && <div className="col-4 title-position">
                    <label htmlFor="date" className="filter-title row m-0 mb-1">{t("TO_DATE")}<p className="text-danger mb-0 font-wieght-bold">&nbsp;*</p></label>
                    <DatePicker 
                        className="custom-calendar"
                        value={toDate} 
                        onChange={(e)=>SetLeaveData(2, e, 0)}
                        format="DD-MM-YYYY"
                        locale={localStorage.getItem('active_language')}
                    />
                </div>}
                {!holidayCodeStatus && <div className="col-4 title-position">
                    <FilterDropdown 
                        filterName={t("HOLIDAY_CODES")}
                        options={holidayCodesList}
                        placeHolder={t("HOLIDAY_CODES_PLACEHOLDER")}
                        isMulti={false}
                        selectedOption={singleHolidayCode[0] ? singleHolidayCode[0]['holiday_code']:''}
                        setSelectedOption={(e) => SetLeaveData(3, e.value, 0)}
                        required={true}
                        isDisabled={true}
                    >
                    </FilterDropdown>
                </div>}
                
            </div>
            <br></br>
            {/* {!multipleStatus && !holidayCodeStatus && <div className="custom-control custom-checkbox top-mar">
                <input type="checkbox" className="custom-control-input" id="half-day" name="half-day" onChange={() => {SetLeaveData(7, !halfDayStatus, 0)}} checked={halfDayStatus}/>
                <label className="custom-control-label" for="half-day">{t("HALF_DAY")}</label>
            </div>} */}
        
            <div className={"custom-control custom-checkbox mt-2 top-mar"}>
                <input type="checkbox" className="custom-control-input" id="multiple-days" name="multiple-days" value={multipleStatus} onChange={() => setMultiplestatus(!multipleStatus)} onClick={()=>setCalendarSelect(false)} checked={multipleStatus} />
                <label className="custom-control-label" for="multiple-days">{t("MULTIPLE_DAYS")}</label>
            </div>
            {/* {!holidayCodeStatus && !halfDayStatus && <div className={"custom-control custom-checkbox mt-2 " + (multipleStatus ? "top-mar" : "")}>
                <input type="checkbox" className="custom-control-input" id="multiple-days" name="multiple-days" value={multipleStatus} onChange={() => setMultiplestatus(!multipleStatus)} onClick={()=>setCalendarSelect(false)} checked={multipleStatus} />
                <label className="custom-control-label" for="multiple-days">{t("MULTIPLE_DAYS")}</label>
            </div>} */}
            {/* {!multipleStatus && !halfDayStatus && <div className={"custom-control custom-checkbox mt-2 " + (holidayCodeStatus ? "top-mar" : "")}>
                <input type="checkbox" className="custom-control-input" id="multiple-holiday-code" name="multiple-holiday-code" onChange={() => setHolidayCodeStatus(!holidayCodeStatus)} checked={holidayCodeStatus}/>
                <label className="custom-control-label" for="multiple-holiday-code">{t("MULTIPLE_HOLIDAY_CODE")}</label>
            </div>} */}
            {multipleStatus && props.updateLeaveData.length === 0 && <div className="custom-control custom-checkbox mt-2">
                <input type="checkbox" className="custom-control-input" id="multiple-days-calendar" name="multiple-days-calendar" onChange={()=>{setCalendarSelect(!calendarSelect)}} checked={calendarSelect}/>
                <label className="custom-control-label" for="multiple-days-calendar">{t("SELECT_IN_CALENDAR")}</label>
            </div>}
            <div className="form-group mt-2">
                <label for="comment" className="filter-title">{t("REASON")}</label>
                <textarea className="form-control form-fields" rows="5" id="comment" value={reason} onChange={(e) => SetLeaveData(4, e.target.value, 0)}></textarea>
            </div>

            {holidayCodeStatus && hoursRow.map((val, index) => {
                return(
                    <div className="form-row col-12 pl-0 pt-3 pb-1 planning-form float-left" key={index}>
                        <div className="col-4 title-position pr-4">
                            <label htmlFor="hours" className="filter-title row m-0 mb-1">{t("HOURS")}<p className="text-danger mb-0 font-wieght-bold">&nbsp;*</p></label>
                            <input type="text" name="hours" value={holidayCode[index] ? holidayCode[index]['hours'] : hours} onChange={(e)=> SetLeaveData(5, e.target.value, index)} className="form-fields form-control"/>
                        </div>
                        
                        <div className="col-4 title-position">
                            <FilterDropdown 
                                filterName={t("HOLIDAY_CODES")}
                                options={hoursHolidayCodesList}
                                placeHolder={t("HOLIDAY_CODES_PLACEHOLDER")}
                                isMulti={false}
                                selectedOption={props.updateLeaveData.length !== 0 && props.updateLeaveData.multiple_holiday_codes  ? val.holiday_code : ''}
                                setSelectedOption={(e) => SetLeaveData(6, e.value, index)}
                                required={true}
                                isDisabled={true}
                            ></FilterDropdown>
                        </div>

                        {index === hoursRow.length-1 &&<div className="col-3 row m-0 my-auto">
                            <Button className='button-style mr-2 mt-1 col-2' onClick={()=>AddMultipleHolidayCodes()}>+</Button>   
                            {hoursRow.length > 1 &&<Button className='button-style mt-1 col-2' onClick={()=>RemoveMultipleHolidayCodes()}>-</Button>}
                        </div>}
                    </div>
                )})
            }
        </form>
    )
}
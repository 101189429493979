import React from "react";
import { useLocation } from "react-router-dom";
import { t } from "../../translations/Translation";
import "../../static/common.css";

export default function Switch({checkAvailability, EnableShifts}){
    let location = useLocation();


    return (
        <div className={(location.hash !== "#week" && location.hash !== '') ? "switch pt-2 pb-3 col-md-11 px-0 mx-auto": "col-md-11 px-0 mx-auto row justify-content-between"}>
            {(location.hash === "#week" || location.hash === '') &&<div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" id="customSwitch1" onChange={(e) => checkAvailability(e)} onInput={(e) => checkAvailability(e)}></input>
                <label className="custom-control-label font-weight-bold" htmlFor="customSwitch1"><p className="filter-title mb-0">{t("AVAILABLE_SWITCH")}</p></label>
            </div>}
            {(location.hash === "#week" || location.hash === '') &&<div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" id="customSwitch2" onChange={(e) => EnableShifts(e)} onInput={(e) => EnableShifts(e)}></input>
                <label className="custom-control-label font-weight-bold" htmlFor="customSwitch2 "><p className="filter-title mb-0">{t("SHIFT_PLANNING")}</p></label>
            </div>}
        </div>
    );
}
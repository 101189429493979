import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { t } from '../../translations/Translation';
import PlanForm from '../atoms/PlanForm';
import "../../utilities/popup/popup.css";
import { APICALL as AXIOS } from "../../services/AxiosServices";
import { DeleteSinglePlan, GetEmployeeTypes, GetFunctions, CreatePlan, AddLeave, AddShift, CancelLeave, DeleteShift } from "../../routes/ApiEndPoints";
import { Alert } from 'react-bootstrap';
import { GetReversedDate } from '../../utilities/CommonFunctions';
import AddLeaveForm from '../atoms/AddLeaveForm';
import CreateShift from '../atoms/CreateShift';


const AddPlan = (props) => {
    const [employeeTypesOptions, setEmployeeTypesOptions] = useState();
    const [functionsOptions, setFunctionsOptions] = useState();
    const [selectedEmployeeTypes, setEmployeeTypeForFunction] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [rowsDatas, setRowsDatas] = useState(props.popupData && props.popupData.plans !== 0 ? props.popupData.plans : [1]);
    const [newPlans, setNewPlans] = useState(props.popupData && props.popupData.plans !== 0 ? props.popupData.plans: []);
    const [leaveData, setLeaveData] = useState({'lid':JSON.parse(localStorage.getItem('location')).value, "token":localStorage.getItem('token'), 'reason':"", 'half_day':0});
    const [shiftData, setShiftData] = useState(
        {'lid':JSON.parse(localStorage.getItem('location')).value,
         'token':localStorage.getItem('token'), 
         'shifts':{},
         'wid':(props.shiftPopup.wid === undefined ? props.shiftPopup : props.shiftPopup.wid),
        });

    const [deleteStatus, setDeleteStatus] = useState(false);

    // Code related to Create or update plan form
    function addPlanRows(){
        const rowsInput= 2;
        if (rowsDatas !== undefined){
        setRowsDatas([...rowsDatas, rowsInput])}
    }
    function deleteTablePlan(index){
        const rows = [...rowsDatas];
        rows.splice(index, 1);
        setRowsDatas(rows);
    }

    useEffect(() => {
        if (props.popupData !== ' ' && props.popupData !== undefined && props.popupData.length !== 0){
            if (props.popupData.plans !== 0){
                addPlanRows()
            }
            let ListData = {
                "lid": JSON.parse(localStorage.getItem('location')).value,
                "date": GetReversedDate(props.popupData.plan_date),
                "eid": props.popupData.employee_id,
                "token":localStorage.getItem('token')
            }

            AXIOS.service(GetEmployeeTypes, "POST", ListData)
            .then(result => {
                if (result && result !== [] && result.status && result.status === 200) {
                    setEmployeeTypesOptions(result.data);
                    if (result.data.length === 1){
                        setEmployeeTypeForFunction(result.data[0].value);
                    }
                } else {
                    setEmployeeTypesOptions([]);
                }
            })
            .catch(error => {
                console.log(error);
            })
        }
    }, [])

    useEffect(() => {

        let selectedLocation = JSON.parse(localStorage.getItem('location')).value;
        setFunctionsOptions([]);
        let data = {
            "eid": props.popupData.employee_id,
            "wid": props.popupData.workstation,
            "lid": selectedLocation,
            "employee_type": selectedEmployeeTypes,
            "token":localStorage.getItem('token')
        }

        if ( props.popupData.employee_id && selectedLocation && selectedEmployeeTypes !== undefined && selectedEmployeeTypes.length !== 0)
        {
            AXIOS.service(GetFunctions, "POST", data)
            .then(result => {
                if (result && result !== [] && result.status === 200) {
                    setFunctionsOptions(result.data);
                } else {
                    setFunctionsOptions([]);
                }
            })
            .catch(error => {
                console.log(error);
            })
        }
    }, [selectedEmployeeTypes, props.popupState])


    const SavePlan = () => {
        if (!props.addLeaveStatus && props.shiftPopup === false) {
            if (newPlans.length === 0) {
                setErrorMessage(t('REQUIRE_MSG'))
            } else {
                let selectedLocation = JSON.parse(localStorage.getItem('location')).value;
                let data = {
                    "eid"      : props.popupData.employee_id,
                    "pdate"    : GetReversedDate(props.popupData.plan_date),
                    "lid"      : selectedLocation,
                    "token"    : localStorage.getItem('token'),
                    "plan_data": JSON.stringify(newPlans),
                    "wid"      : props.popupData.workstation
                }

                AXIOS.service(CreatePlan, "POST", data)
                .then(result => {
                    if (result && result !== [] && result.status === 200) {
                            let saved_emp = JSON.parse(localStorage.getItem('saved_employees'))
                            let availableDates = props.availableDates
                            let unAvailableDates = props.unAvailableDates
                            let remarkDates = props.remarkDates
                            for(let i=0;i<=saved_emp.length-1;i++){
                                if (saved_emp[i] && saved_emp[i][props.popupData.workstation] && saved_emp[i][props.popupData.workstation].value === props.popupData.employee_id){
                                    props.rowsData.splice(i, 1);
                                }
                            }
                            for(let i=0;i<=saved_emp.length-1;i++){
                                if (saved_emp[i] && saved_emp[i][props.popupData.workstation] && saved_emp[i][props.popupData.workstation].value === props.popupData.employee_id){
                                    saved_emp.splice(i, 1)
                                    availableDates.splice(i, 1); unAvailableDates.splice(i, 1); remarkDates.splice(i,1);
                                }
                            }
                            localStorage.setItem('saved_employees', JSON.stringify(saved_emp));
                            props.setAvailableDates(availableDates);
                            props.setUnAvailableDates(unAvailableDates);
                            props.setRemarkDates(remarkDates);
                            props.setSearchedEmployee(saved_emp);
                            props.setSuccessMessage(result.message);
                            props.setShiftStatus(false);
                            props.onHide();
                        
                    } else {
                        if (props.selectedShift.length !== 0 && props.enableShifts){
                            props.setErrorMessagePopup(result.message);
                        } else {
                            setErrorMessage(result.message)
                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                })
            }
        }else if (props.addLeaveStatus){
            if (props.updateLeaveData!== undefined && props.updateLeaveData.length !== 0){leaveData['leave_id'] = props.updateLeaveData.leave_id}
            if (typeof(leaveData['holiday_code']) !== 'string'){
                leaveData['holiday_code'] = JSON.stringify(leaveData['holiday_code'])
            }
            if (typeof(leaveData['dates']) !== 'string'){
                leaveData['dates'] = JSON.stringify(leaveData['dates'])
            }
            if (leaveData.dates !== {} && leaveData['holiday_code'] !== undefined){
                AXIOS.service(AddLeave, "POST", leaveData)
                    .then(result => {
                        if (result && result !== [] && result.status === 200) {
                            props.setSuccessMessage(result.message);
                            props.onHide();
                        } else {
                            setErrorMessage(result.message)
                            leaveData['holiday_code'] = JSON.parse(leaveData['holiday_code'])
                            leaveData['dates'] = JSON.parse(leaveData['dates'])
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }else{
                setErrorMessage(t("REQUIRE_MSG"))
            }
        }else if (props.shiftPopup !== false){
            if (shiftData.shifts !== undefined && shiftData.shifts[0] !== undefined){
                if (typeof(shiftData['shifts']) !== 'string'){
                    shiftData['shifts'] = JSON.stringify(shiftData['shifts'])
                }
                AXIOS.service(AddShift, "POST", shiftData)
                .then(result => {
                    if (result && result !== [] && result.status === 200) {
                        props.setSuccessMessage(result.message);
                        props.onHide();
                    } else {
                        setErrorMessage(result.message)
                        shiftData['shifts'] = JSON.parse(shiftData['shifts'])
                    }
                })
                .catch(error => {
                    console.log(error);
                })
            }else{
                setErrorMessage(t("REQUIRE_MSG"))
            }
        }
    }

    const setSelectedOptions = (newPlanss, index) => {
        if (newPlans[index] === undefined) newPlans[index] = {}
        newPlans[index] = newPlanss[index];
    }

    const DeleteEachPlan = (plan_id, index) => {
        if (plan_id !== 0 && plan_id !== undefined){
            let data = {
                'token': localStorage.getItem('token'),
                'pid'  : plan_id,
            }
            AXIOS.service(DeleteSinglePlan, 'POST', data)
            .then((result) => {
                if (result.status === 200){
                    if (index === 0 && rowsDatas.length === 1){
                        props.setSuccessMessage(result.message);
                        props.onHide();
                    }
                    deleteTablePlan(index)
                    props.DeletePlan(props.popupData , plan_id);
                }else{
                    setErrorMessage(result.message)
                }
            })
            .catch((error) => {
                console.log(error);
            })
        }else{
            props.DeletePlan(props.popupData , 0);
            deleteTablePlan(index) 
        }
    }

    //Code related to add leave
    const DeleteLeave = () => {
        let data = {
            "eid": props.updateLeaveData.employee.value,
            "leave_id": props.updateLeaveData.leave_id,
            "token": localStorage.getItem('token')
        }
        AXIOS.service(CancelLeave, 'POST', data)
            .then((result) => {
                if (result.status === 200){
                    props.setSuccessMessage(result.message);
                    props.onHide();
                }else{
                    setErrorMessage(result.message)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const DeleteShifts = (data) => {
        AXIOS.service(DeleteShift, 'POST', data)
            .then((result) => {
                if (result.status === 200){
                    props.setSuccessMessage(result.message);
                    props.onHide();
                }else{
                    setErrorMessage(result.message)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    return (
        <Modal
            show={props.addLeaveStatus || props.shiftPopup !== false ? true :  props.popupState}
            onHide={props.onHide}
            size={props.size ? props.size : "xl"}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={deleteStatus ? "delete-popup": ''}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter" className='mx-auto title-color font-weight-bold'>
                    {props.addLeaveStatus ? (props.updateLeaveData !== undefined && props.updateLeaveData.length === 0 ? t("ADD_LEAVE"): t('UPDATE_LEAVE')) :(props.shiftPopup === false ? props.title: ("Add/update Shifts"))}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='m-4 border pt-0'>
                {errorMessage && <Alert variant='danger' onClose={()=>setErrorMessage('')} dismissible>{(errorMessage)}</Alert>}
                {props.addLeaveStatus === false && props.shiftPopup === false && rowsDatas.map((value, index)=>{
                    return(
                        <PlanForm 
                            key={index}
                            index={index}
                            employeeTypesOptions={employeeTypesOptions} 
                            FunctionOptions={functionsOptions}
                            defaultValues={props.popupData.plans !== undefined && props.popupData.plans.length !== 0 ? props.popupData.plans[index]: undefined}
                            DeleteEachPlan={DeleteEachPlan}
                            setDeleteStatus={setDeleteStatus}
                            addPlanRows={addPlanRows}
                            rowsData={rowsDatas}
                            workstation={props.popupData.workstation !== undefined ? props.popupData.workstation : ''}
                            setSelectedOptions={setSelectedOptions}
                            allPlans = {props.popupData.plans !== 0 ? props.popupData.plans : newPlans}
                            employeeType = {props.popupData.employee_type !== undefined ? props.popupData.employee_type : ''}
                            functionId = {props.popupData.function_id !== undefined ? props.popupData.function_id : ''} 
                            setEmployeeTypeForFunction={setEmployeeTypeForFunction}
                            selectedShift = {props.selectedShift !== undefined ? props.selectedShift : []}
                            enableShifts={props.enableShifts !== undefined ? props.enableShifts : false}
                        ></PlanForm>
                    )
                })}
                {props.addLeaveStatus && 
                    <AddLeaveForm 
                        names={props.names}
                        leaveData={leaveData}
                        updateLeaveData={props.updateLeaveData === undefined ? []: props.updateLeaveData}
                    ></AddLeaveForm>
                }
                {props.shiftPopup !== false && 
                    <CreateShift shiftData={shiftData} defaultValues={props.shiftPopup} DeleteShifts={DeleteShifts}></CreateShift>
                }
            </Modal.Body>
            <Modal.Footer>
                {props.updateLeaveData !== undefined && props.updateLeaveData.length !== 0 && <Button className='button-style' onClick={(e)=>DeleteLeave(e)}>
                    {t('CANCEL_LEAVE')}
                </Button>}
                <Button className='button-style' onClick={(e)=>SavePlan(e)}>
                    {t('SAVE')}
                </Button>
                <Button className='button-style' onClick={props.onHide}>
                    {t('CLOSE')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddPlan

export const ENV_URL = localStorage.getItem('domain')//window.location.origin;
export const LANG = localStorage.getItem('active_language') || 'en';
export const REGEX_URL = "/" + LANG + '/api/';
export const BASE_URL = ENV_URL + REGEX_URL;
export const LogoutLink = '';
export const PAGINATE_BY = 10;

//follow the below example to add your url endpoints

// EXAMPLE : export const getUserDetails = BASE_URL + 'account/get-user-details';

//add all your new urls from here onwards


//Translations API urls starts here
// export const getLangaugeList = BASE_URL + 'translations/get-all-languages';
export const fetchTranslations = BASE_URL + 'translate_strings';
// export const fetchAllTranslations = BASE_URL + 'translations/get-all-strings';

export const GetWorkstations = BASE_URL + 'get_workstations';
export const GetEmployeeTypes = BASE_URL + 'get_employee_types';
export const GetLocations = BASE_URL + 'get_locations';
export const GetFunctions = BASE_URL + 'get_employee_functions';
export const CreatePlan = BASE_URL + 'add_plan';
export const GetWeeklyPlans = BASE_URL + 'get_planning_for_week';
export const GetPlanningDates = BASE_URL + 'get_planning_dates';
export const GetEmployees = BASE_URL + 'get_employees_on_location';
export const GetPlansByDate = BASE_URL + 'get_planning_for_date';
export const GetEmployeeDetails = BASE_URL + 'get_user_details';
export const DeleteSinglePlan = BASE_URL + 'cancel_plan';
export const DeleteWeeklyPlans = BASE_URL + 'cancel_complete_week_plans';
export const GetAvailability = BASE_URL + 'get_employee_availability';
export const GetHolidayCodes = BASE_URL + 'get_holiday_codes_for_employee';
export const AddLeave = BASE_URL + 'add_leave';
export const AddShift = BASE_URL + 'shift';
export const GetEmployeesForLeave = BASE_URL + 'get_employees_for_leave';
export const CancelLeave = BASE_URL + 'cancel_leave';
export const DeleteShift = BASE_URL + 'delete_shifts';
// export const AuthUserId = BASE_URL + 'set_session_testing';

//new api urls
export const GetWeeklyPlansWorkstations = BASE_URL + 'get_week_plan_workstations_shifts';
export const GetWeeklyPlansEmployees = BASE_URL + 'get_week_plan_employees_for_workstation';
export const GetWeeklyPlansCosts = BASE_URL + 'get_week_plan_total_cost_contract_hours';
export const GetWeeklyPlansForEmployee = BASE_URL + 'get_week_plans_for_employeee';
import { BrowserRouter as Router} from 'react-router-dom';
import  BaseRouter  from "./routes/Routes";
import { AppContext } from "./routes/ContextLib";
import * as React from 'react';
import './App.css';
import { GetFormattedDate, getWeekNumberByDate } from './utilities/CommonFunctions';
import LoadingIcon from './utilities/LoadingIcon';
import { GetTranslatedConstants } from './translations/Translation';

function App() {
      GetTranslatedConstants()
      
      // Saving basic data in localStorage like current date, time, week number, year, month, saved employees and workstations
      let currentDate = new Date();
      let startDate = new Date(currentDate.getFullYear(), 0, 1);
      let days = Math.floor((currentDate - startDate) /
         (24 * 60 * 60 * 1000));
            
      let weekNumber = Math.ceil(days / 7);
      localStorage.setItem('current_week', weekNumber)
      localStorage.setItem('current_date', GetFormattedDate(currentDate, currentDate.getFullYear()))
      if (!(localStorage.getItem('week_number'))) {
         localStorage.setItem('week_number', weekNumber);
      }
      localStorage.setItem('year', currentDate.getFullYear());
      if (!(localStorage.getItem('month'))) { 
         localStorage.setItem('month', (currentDate.getMonth() + 1));
      }
      if (!(localStorage.getItem('date'))) { 
         localStorage.setItem('date', GetFormattedDate(currentDate, currentDate.getFullYear())); 
      }
      let lastWeek = getWeekNumberByDate(currentDate.getFullYear()+'-12-31');
      localStorage.setItem('last_week', lastWeek);

      localStorage.setItem('saved_employees', '[]');
      localStorage.setItem('saved_workstations', '[]');
   
      return (  
      <div>  
         {/* check for authentication of user before displaying anything*/}
         <AppContext.Provider value="">
            <Router>
               <BaseRouter></BaseRouter>
               <LoadingIcon></LoadingIcon>
            </Router>
         </AppContext.Provider>
      </div>  
      );  
}

export default App;

import React from "react";
import { t } from "../../translations/Translation";
import "../../static/common.css";
import FilterDropdown from "../atoms/FilterDropdown";


export default function Filters({locationOptions, workStationOptions, employeeTypeOptions, setSelectedOptions, ApplyFilters}) {
    

    return (
            <div className="col-md-12 d-flex display-inline justify-content-center p-0">
                <div className="filter-dropdown">
                    {locationOptions !== undefined && <FilterDropdown filterName={t("LOCATION")}  options={locationOptions} placeHolder={t("LOCATION_PLACEHOLDER")} isMulti={false} selectedOption={localStorage.getItem('location') !== '' ? JSON.parse(localStorage.getItem('location')): ''} setSelectedOption={(e) => setSelectedOptions(e, 0)} required={true} isDisabled={true}></FilterDropdown>}
                </div>
                <div className="filter-dropdown">
                    <FilterDropdown filterName={t("WORKSTATION")} options={workStationOptions} placeHolder={t("WORKSTATION_PLACEHOLDER")} isMulti={true} selectedOption={localStorage.getItem('workstation') !== (undefined || '') ? JSON.parse(localStorage.getItem('workstation')): ''} setSelectedOption={(e) => setSelectedOptions(e, 1)} required={false} isDisabled={true}></FilterDropdown>
                </div>
                <div className="filter-dropdown">
                    <FilterDropdown filterName={t("EMPLOYEE_TYPE")} options={employeeTypeOptions} placeHolder={t("EMPLOYEE_TYPES_PLACEHOLDER")} isMulti={true} selectedOption={localStorage.getItem('employee_type') !== (undefined || '') ? JSON.parse(localStorage.getItem('employee_type')): ''} setSelectedOption={(e) => setSelectedOptions(e, 2)} required={false} isDisabled={true}></FilterDropdown>
                </div>
                <div className="filter-button mt-auto">
                    <button className="btn button-style" onClick={(e) => ApplyFilters(e)}><p className="mb-0 pointer">{t('FILTER')}</p></button>
                </div>
            </div>
    );
}
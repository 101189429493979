import React, {useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './calendar.css';
import { GetFormattedDate, getWeekNumberByDate } from '../CommonFunctions';


export default function CalendarLayout(props) {
  const [value, onChange] = useState(new Date());
  

  const RedirectToWeekTab = (e) => {
    localStorage.setItem('week_number', e);
    window.location.hash = '#week';
  }
  const RedirectToDayTab = (e) => {
    localStorage.setItem('date', GetFormattedDate(e, e.getFullYear()))
    window.location.hash = '#day';
  }
  const UpdateYear = (e) => {
    localStorage.setItem('year', e.activeStartDate.getFullYear());
    let lastWeek = getWeekNumberByDate(e.activeStartDate.getFullYear()+'-12-31');
    localStorage.setItem('last_week', lastWeek);
    localStorage.setItem('month', e.activeStartDate.getMonth() + 1);
    props.changeMonth();
  }


  return (
    <div>
      <Calendar 
          onChange={onChange} 
          value={value} 
          defaultValue={new Date()}
          className="col-md-12 p-0"
          showWeekNumbers = {true}
          showNavigation = {true}
          next2Label={null}
          prev2Label={null}
          locale={localStorage.getItem('active_language') || 'en'}
          onClickWeekNumber = { (e) => { RedirectToWeekTab(e) } }
          onClickDay = { (e) => { RedirectToDayTab(e) } }
          onActiveStartDateChange={ (e) => {UpdateYear(e)} }
          onClickMonth={(e) => {UpdateYear(e)}}
          tileContent ={ (e) => props.planData[GetFormattedDate(e.date, e.date.getFullYear())] ? <p className='m-0 p-0 text-left plan-icon h-0'>{props.planData[GetFormattedDate(e.date, e.date.getFullYear())]}</p> : '' }
      />
    </div>
  );
}
// Function to make the given number 2 digit
export function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

// Function to format the date
export function GetFormattedDate(date, year) {
    if (year) {
        return padTo2Digits(date.getDate())+ '-' + padTo2Digits(date.getMonth()+1) + '-' + year;
    } else {
        return padTo2Digits(date.getDate())+ '-' + padTo2Digits(date.getMonth()+1) + '-' + date.getFullYear();
    }
}

// Function to redirect to day tab
export function RedirectToDayTab(e) {
    localStorage.setItem('date', e)
    window.location.hash = '#day';
}

// Function to redirect to week tab
export function RedirectToWeekTab(e) {
    localStorage.setItem('week_number', e);
    window.location.hash = '#week';
}

// Function to redirect to month tab
export function RedirectToMonthTab(e) {
    window.location.hash = '#month';
}

// Function to reverse the date
export function GetReversedDate(date){
    const [day, month, year] = date.split('-');
    const reversed_date = [year, month, day].join('-');
    return reversed_date;
}

// Function to get time difference for contract hours
export function GetTimeDifference(start_time, end_time) {
    start_time = padTo2Digits(start_time.split(':')[0]) + ':' + padTo2Digits(start_time.split(':')[1])
    end_time = padTo2Digits(end_time.split(':')[0]) + ':' + padTo2Digits(end_time.split(':')[1])

    let start_time_obj = new Date("1970-01-01 " +  start_time);
    if (start_time > end_time) {
        var end_time_obj = new Date("1970-01-02 " +  end_time);
    } else {
        end_time_obj = new Date("1970-01-01 " +  end_time);
    }
    return ((end_time_obj - start_time_obj)/1000/60/60);
}

// Function to check the break time based on location data
export function checkBreaktime(planning_time) {
    let configData = JSON.parse(localStorage.getItem('configData'))
    let workperiod = configData.workperiod
    let operator = configData.operator
    let formulabreak_value = configData.formulabreak_value

        if  (((operator === '=') && (planning_time === workperiod)) ||
             ((operator === '>') && (planning_time > workperiod))  ||
             ((operator === '<') && (planning_time < workperiod))  ||
             ((operator === '>=') && (planning_time >= workperiod))||
             ((operator === '<=') && (planning_time <= workperiod))
            ){
                planning_time = planning_time - formulabreak_value;
        } 
        return planning_time;
}

// Function to get week number based on date
export function getWeekNumberByDate(date){
    let currentDate = new Date(date);
    let startDate = new Date(currentDate.getFullYear(), 0, 1);
    let days = Math.floor((currentDate - startDate) /
        (24 * 60 * 60 * 1000));
        
    let weekNumber = Math.ceil(days / 7);
    return weekNumber;
}

// Function to get dates based on given week number
export function getDatesByWeekNumber(){
    let dates_array = [];
    let w = localStorage.getItem('week_number');
    let y = localStorage.getItem('year');
    
    let date = new Date(y, 0, ((1 + (w - 1) * 7)));
    if (date.getDay() === 0){
        var date1 = new Date(y, 0, ((1 + (w - 1) * 7) + 1));
        var date2 = new Date(y, 0, ((1 + (w - 1) * 7) + 2));
        var date3 = new Date(y, 0, ((1 + (w - 1) * 7) + 3));
        var date4 = new Date(y, 0, ((1 + (w - 1) * 7) + 4));
        var date5 = new Date(y, 0, ((1 + (w - 1) * 7) + 5));
        var date6 = new Date(y, 0, ((1 + (w - 1) * 7) + 6));
        var date7 = new Date(y, 0, ((1 + (w - 1) * 7) + 7));
    } else if (date.getDay() === 1){
        date1 = new Date(y, 0, ((1 + (w - 1) * 7) + 0));
        date2 = new Date(y, 0, ((1 + (w - 1) * 7) + 1));
        date3 = new Date(y, 0, ((1 + (w - 1) * 7) + 2));
        date4 = new Date(y, 0, ((1 + (w - 1) * 7) + 3));
        date5 = new Date(y, 0, ((1 + (w - 1) * 7) + 4));
        date6 = new Date(y, 0, ((1 + (w - 1) * 7) + 5));
        date7 = new Date(y, 0, ((1 + (w - 1) * 7) + 6));
    } else if(date.getDay() === 2){
        date1 = new Date(y, 0, ((1 + (w - 1) * 7) + -1));
        date2 = new Date(y, 0, ((1 + (w - 1) * 7) + 0));
        date3 = new Date(y, 0, ((1 + (w - 1) * 7) + 1));
        date4 = new Date(y, 0, ((1 + (w - 1) * 7) + 2));
        date5 = new Date(y, 0, ((1 + (w - 1) * 7) + 3));
        date6 = new Date(y, 0, ((1 + (w - 1) * 7) + 4));
        date7 = new Date(y, 0, ((1 + (w - 1) * 7) + 5));
    } else if(date.getDay() === 3){
        date1 = new Date(y, 0, ((1 + (w - 1) * 7) + -2));
        date2 = new Date(y, 0, ((1 + (w - 1) * 7) + -1));
        date3 = new Date(y, 0, ((1 + (w - 1) * 7) + 0));
        date4 = new Date(y, 0, ((1 + (w - 1) * 7) + 1));
        date5 = new Date(y, 0, ((1 + (w - 1) * 7) + 2));
        date6 = new Date(y, 0, ((1 + (w - 1) * 7) + 3));
        date7 = new Date(y, 0, ((1 + (w - 1) * 7) + 4));
    } else if(date.getDay() === 4){
        date1 = new Date(y, 0, ((1 + (w - 1) * 7) + -3));
        date2 = new Date(y, 0, ((1 + (w - 1) * 7) + -2));
        date3 = new Date(y, 0, ((1 + (w - 1) * 7) + -1));
        date4 = new Date(y, 0, ((1 + (w - 1) * 7) + 0));
        date5 = new Date(y, 0, ((1 + (w - 1) * 7) + 1));
        date6 = new Date(y, 0, ((1 + (w - 1) * 7) + 2));
        date7 = new Date(y, 0, ((1 + (w - 1) * 7) + 3));
    } else if(date.getDay() === 5){
        date1 = new Date(y, 0, ((1 + (w - 1) * 7) + -4));
        date2 = new Date(y, 0, ((1 + (w - 1) * 7) + -3));
        date3 = new Date(y, 0, ((1 + (w - 1) * 7) + -2));
        date4 = new Date(y, 0, ((1 + (w - 1) * 7) + -1));
        date5 = new Date(y, 0, ((1 + (w - 1) * 7) + 0));
        date6 = new Date(y, 0, ((1 + (w - 1) * 7) + 1));
        date7 = new Date(y, 0, ((1 + (w - 1) * 7) + 2));
    } else {
        date1 = new Date(y, 0, ((1 + (w - 1) * 7) + 2));
        date2 = new Date(y, 0, ((1 + (w - 1) * 7) + 3));
        date3 = new Date(y, 0, ((1 + (w - 1) * 7) + 4));
        date4 = new Date(y, 0, ((1 + (w - 1) * 7) + 5));
        date5 = new Date(y, 0, ((1 + (w - 1) * 7) + 6));
        date6 = new Date(y, 0, ((1 + (w - 1) * 7) + 7));
        date7 = new Date(y, 0, ((1 + (w - 1) * 7) + 8));
    }

    dates_array = [GetFormattedDate(date1), GetFormattedDate(date2), GetFormattedDate(date3), GetFormattedDate(date4), GetFormattedDate(date5), GetFormattedDate(date6), GetFormattedDate(date7)];
    return dates_array;
}
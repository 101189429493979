import React, {useEffect, useState} from "react";
import {t} from "../../translations/Translation";
import { checkBreaktime, GetTimeDifference } from "../../utilities/CommonFunctions";
import FilterDropdown from "./FilterDropdown";
import DeleteIcon from "../../static/icons/Delete.svg";
import AddIcon from "../../static/icons/addPlan.png"
import ModalPopup from "../../utilities/popup/Popup";
import TimePicker from "../../utilities/Timepicker";


export default function PlanForm({workstation, employeeTypesOptions, FunctionOptions, setSelectedOptions, index, defaultValues, allPlans, setEmployeeTypeForFunction, DeleteEachPlan, addPlanRows, rowsData, employeeType, functionId, setDeleteStatus, selectedShift, enableShifts}){
    const [selectedFunctions, setSelectedFunctions] = useState('');
    setEmployeeTypeForFunction(defaultValues !== undefined && defaultValues.employee_type ? defaultValues.employee_type :'');
    
    const [starttimeVal, setStarttimeValDisplay] = useState(defaultValues !== undefined ? defaultValues.start_time : '');
    const [endtimeVal, setEndtimeValDisplay] = useState(defaultValues !== undefined ? defaultValues.end_time : '');
    const [contrctHours , setContractHours] = useState(defaultValues !== undefined ? defaultValues.contract_hours : '');
    const [breakTime, setBreakTime] = useState(defaultValues !== undefined && defaultValues.break_time !== null ? defaultValues.break_time : '');
    const [newPlans, setNewPlans] = useState(allPlans);
    const [warningMessage, setWarningMessage] = useState('');
    const [plan_id, setPlanId] = useState(0);
    const [startPicker, setStartPicker] = useState(false);
    const [endPicker, setEndPicker] = useState(false);
    const deletable = defaultValues !== undefined ? defaultValues.delete_status : 1;
    const editable = defaultValues !== undefined ? defaultValues.edit_status : 1;

    const hideTimePopup = () => {
        setStartPicker(false)
        setEndPicker(false)
    }

    useEffect(() => {
        setStartPicker(false)
        setEndPicker(false)
        if (defaultValues === undefined){
            setStarttimeValDisplay('');
            setEndtimeValDisplay('');
            setBreakTime('');
            setContractHours('');
            setEmployeeTypeForFunction(employeeType);
            setSelectedFunctions(functionId);
        } else {
            setStarttimeValDisplay(defaultValues.start_time);
            setEndtimeValDisplay(defaultValues.end_time);
            setBreakTime(defaultValues.break_time);
            setContractHours(defaultValues.contract_hours);
        }
        if (defaultValues !== undefined && selectedShift.length !== 0 && enableShifts){
            setTime(defaultValues.start_time, 1, 0);
            setTime(defaultValues.end_time, 2, 0);
            setTime(defaultValues.break_time, 3, 0);
            setTime(defaultValues.contract_hours, 4, 0);
        }
    }, [defaultValues])


    // Function to set form data into required format for api call
    const setTime = (data, type, index) => {
        if (newPlans[index] === undefined){ newPlans[index] = {} }
        
        newPlans[index].workstation_id = workstation
        if (employeeTypesOptions !== undefined && employeeTypesOptions.length === 1) {
            newPlans[index].employee_type = employeeTypesOptions[0].value;
            setEmployeeTypeForFunction(employeeTypesOptions[0].value);
        }
        if (FunctionOptions !== undefined && FunctionOptions.length === 1) {
            newPlans[index].function_id = FunctionOptions[0].value;
            setSelectedFunctions(FunctionOptions[0].value);
        }
        if (type === 1){
            setStarttimeValDisplay(data);
            newPlans[index].start_time = data;
            newPlans[index].employee_type = newPlans[0] !== undefined && newPlans[0].employee_type ? newPlans[0].employee_type : employeeType;
            newPlans[index].function_id = newPlans[0] !== undefined && newPlans[0].function_id ? newPlans[0].function_id : functionId;
            if (newPlans[index].end_time) {
                if (newPlans[index].break_time){
                    var contract_hr = (GetTimeDifference(data, newPlans[index].end_time) - (newPlans[index].break_time/60)).toFixed(2);
                }else{
                    contract_hr = (checkBreaktime(GetTimeDifference(data, newPlans[index].end_time))).toFixed(2);
                }
            }
            newPlans[index].contract_hours = contract_hr
            setContractHours(contract_hr)

        } else if (type === 2) {
            newPlans[index].end_time = data;    
            setEndtimeValDisplay(data);
            if (newPlans[index].start_time) {
                if (newPlans[index].break_time){
                    var contract_hr = (GetTimeDifference(newPlans[index].start_time, data) - (newPlans[index].break_time/60)).toFixed(2);
                }else{
                    contract_hr = (checkBreaktime(GetTimeDifference(newPlans[index].start_time, data))).toFixed(2);
                }

                newPlans[index].contract_hours = contract_hr
                setContractHours(contract_hr)
            }

        }else if (type === 3){
            newPlans[index].break_time = data
            setBreakTime(data);
            if (newPlans[index].start_time && newPlans[index].end_time) {
                let contract_hr = (GetTimeDifference(newPlans[index].start_time, newPlans[index].end_time) - (data/60)).toFixed(2);
                if (contract_hr < 0) { contract_hr = 0}
                newPlans[index].contract_hours = contract_hr
                setContractHours(contract_hr)
            }

        }else if (type === 4){
            newPlans[index].contract_hours = data
            setContractHours(data);
        }else if (type === 5){
            newPlans[index].workstation_id = data.value
        }else if (type === 6){
            newPlans[index].employee_type = data.value
            setEmployeeTypeForFunction(data.value);
        }else{
            newPlans[index].function_id = data.value
            setSelectedFunctions(data.value);
        }
        if (newPlans[index].break_time === undefined){newPlans[index].break_time = ""}
        setSelectedOptions(newPlans, index);
    }

    const getConfirmPopup = (defaultValues) => {
        if (defaultValues !== undefined){
            setDeleteStatus(true);
            setWarningMessage(t('CONFIRM_DELETE'));
            setPlanId(defaultValues.plan_id)
        }else{
            DeleteEachPlan(0, index)
        } 
    }

    const onConfirm = () => {
        DeleteEachPlan(plan_id, index);
        setWarningMessage('');
        setDeleteStatus(false)
    }

    const onCancel = () => {
        setDeleteStatus(false);
        setWarningMessage('')
    }
    
    // Get hour and min separately and combine and display
    const setHourMin = (val, type, index, time) => {
        let hour
        let min
        if (time === 1 && type === 1){
            hour = val
            starttimeVal && starttimeVal.split(':')[1] !== "00" ? min = starttimeVal.split(':')[1] : min="00"
        }else if (time === 2 && type === 1){
            min = val
            starttimeVal && starttimeVal.split(':')[0] !== "00" ? hour = starttimeVal.split(':')[0] : hour="00"
        }else if (time === 1 && type === 2){
            hour = val
            endtimeVal && endtimeVal.split(':')[1] !== "00" ? min = endtimeVal.split(':')[1] : min="00"
        }else {
            min = val
            endtimeVal && endtimeVal.split(':')[0] !== "00" ? hour = endtimeVal.split(':')[0] : hour="00"
        }
        let selTime = hour + ':' + min

        type === 1 ? setStarttimeValDisplay(selTime) : setEndtimeValDisplay(selTime)
        setTime(selTime, type, index) 
    }

    return(
        <section>
        {warningMessage && <ModalPopup title={t('WARNING')} body={(warningMessage)} deleteTableRows={() => onConfirm()} onHide={() => onCancel()} buttonName={t('NO_CANCEL')}></ModalPopup>}
        {index === 0 && <div className="form-row p-3 pb-4 mb-2" onClick={() => hideTimePopup()}>
            <div className="col-4 pl-0 pt-2">
                {employeeTypesOptions !== undefined && <FilterDropdown
                    filterName={t("EMPLOYEE_TYPE")}
                    options={employeeTypesOptions}
                    placeHolder={t("EMPLOYEE_TYPES_PLACEHOLDER")}
                    isMulti={false}
                    selectedOption={defaultValues!== undefined ? {'value':defaultValues.employee_type, 'label': defaultValues.employee_type_name} : ''}
                    setSelectedOption={(e) => setTime(e, 6, 0)}
                    required={true}
                    isDisabled={selectedShift.length === 0 ? (editable !== undefined ? editable : false): true}
                ></FilterDropdown>}
            </div>
            <div className="col-4 pt-2">
                {FunctionOptions !== undefined && <FilterDropdown
                    filterName={t("FUNCTION")}
                    options={FunctionOptions}
                    placeHolder={t("FUNCTION_PLACEHOLDER")}
                    isMulti={false}
                    selectedOption={defaultValues!== undefined ? {'value':defaultValues.function_id, 'label':defaultValues.function_name} : ''}
                    setSelectedOption={(e) => setTime(e, 7, 0)}
                    required={true}
                    isDisabled={selectedShift.length === 0 ? (editable !== undefined ? editable : false): true}
                ></FilterDropdown>}
            </div>
        </div>}
        <form className="p-2 pl-3" index={index}>
            <div className="form-row col-11 p-2 py-3 mb-3 border planning-form float-left">
                <div className="col-3 title-position">
                    <label htmlFor="start_time" onClick={() => hideTimePopup()} className="filter-title row m-0 mb-1">{t('START_TIME')}<p className="text-danger mb-0 font-wieght-bold">&nbsp;*</p></label>
                    {startPicker && <TimePicker hour={starttimeVal ? starttimeVal.split(':')[0] :"00"} minute={starttimeVal ? starttimeVal.split(':')[1] :"00"} index={index} type={1} setHourMin={setHourMin}></TimePicker>}
                    <input type="" name="start_time" onClick={() => {setStartPicker(true); setEndPicker(false)}} value={starttimeVal} onChange={(e)=> setTime(e.target.value, 1, index)} pattern="([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}" className={"form-fields form-control" + (editable === 1 || editable === undefined ? "":" disabled-field")}  disabled={editable === 1 || editable === undefined ? false: true}/>
                </div>
                <div className="col-3 title-position">
                    <label htmlFor="end_time" onClick={() => hideTimePopup()} className="filter-title row m-0 mb-1">{t('END_TIME')}<p className="text-danger mb-0 font-wieght-bold">&nbsp;*</p></label>
                    {endPicker && <TimePicker hour={endtimeVal ? endtimeVal.split(':')[0] :"00"} minute={endtimeVal ? endtimeVal.split(':')[1] :"00"} index={index} type={2} setHourMin={setHourMin}></TimePicker>}
                    <input type="" name="end_time" onClick={() => {setEndPicker(true); setStartPicker(false);}} value={endtimeVal} onChange={(e)=> setTime(e.target.value, 2, index)}  pattern="([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}" className={"form-fields form-control" + (editable === 0 && editable !== undefined ? " disabled-field" : "")} disabled={editable === 0 && editable !== undefined ? true: false}/>
                </div>
                <div className="col-3 title-position" onClick={() => hideTimePopup()}>
                    <label htmlFor="break_time" className="filter-title mb-1">{t('BREAK_TIME')}</label>
                    <input type="number" className={"form-fields form-control" + (editable === 0 && editable !== undefined ? " disabled-field" : "")} value={breakTime} onChange={(e)=> setTime(e.target.value, 3, index)} name="break_time" required disabled={editable === 0 && editable !== undefined ? true : false}></input>
                </div>
                <div className="col-3 title-position" onClick={() => hideTimePopup()}>
                    <label htmlFor="contract_hours" className="filter-title row m-0 mb-1">{t('CONTRACT_HOURS')}<p className="text-danger mb-0 font-wieght-bold">&nbsp;*</p></label>
                    <input type="text" className={"form-fields form-control" + (editable === 0 && editable !== undefined ? " disabled-field" : "")} value={contrctHours} onChange={(e)=> setTime(e.target.value, 4, index)} name="contract_hour" required disabled={editable === 0 && editable !== undefined ? true : false}></input>
                </div>
            </div>
            {defaultValues === undefined && rowsData.length - 1 === index && !enableShifts && <div className="border form-row col-1 float-right d-flex justify-content-center align-content-center planning-form" onClick={() => hideTimePopup()}><img src={AddIcon} className="image-icon pointer" onClick={() => addPlanRows()} alt="add plan"></img></div>}
            {(rowsData.length - 1 !== index || defaultValues !== undefined) && !enableShifts && <div className="border form-row col-1 float-right d-flex justify-content-center align-content-center planning-form" onClick={() => hideTimePopup()}><img src={ DeleteIcon} className={"image-icon pointer" + (deletable !== undefined && !deletable ? " disabled-icon": "")} onClick={() => deletable === undefined || deletable ? getConfirmPopup(defaultValues) : '' } alt="delete plan"></img></div>}
        </form>
        <br></br>
        </section>
    )
}
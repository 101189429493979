import React, { useState, useEffect } from "react";
import "../../static/common.css";
import CalendarLayout from "../../utilities/calendar/CalendarLayout";
import { APICALL as AXIOS } from "../../services/AxiosServices";
import { GetPlanningDates } from "../../routes/ApiEndPoints";


export default function MonthlyTab(props) {
    const [planData, setPlanData] = useState([]);
    const [monthChangeStatus, setMonthChangeStatus] = useState(false);
    let year = localStorage.getItem('year');
    let month = localStorage.getItem('month');

    const changeMonth = () => {
        setMonthChangeStatus(!monthChangeStatus);
    }

    useEffect(() => {
        let wids = {};
        let employee_types = {};
        if (localStorage.getItem('workstation') !== '' && localStorage.getItem('workstation') !== undefined && localStorage.getItem('workstation') !== 'null' && localStorage.getItem('workstation') !== null){
            let wsData = JSON.parse(localStorage.getItem('workstation'));
            for (let i=0; i<=wsData.length-1;i++){
                wids[i] = (wsData[i].value)
            }
        }
        if (localStorage.getItem('employee_type') !== '' && localStorage.getItem('employee_type') !== undefined && localStorage.getItem('employee_type') !== 'null' && localStorage.getItem('employee_type') !== null){
            let etData = JSON.parse(localStorage.getItem('employee_type'));
            for (let i=0; i<=etData.length-1;i++){
                employee_types[i] = (etData[i].value)
            }
        }

        if (localStorage.getItem('location'))
        {
            let data = {
                'year': year,
                'month': month,
                'lid' : JSON.parse(localStorage.getItem('location')).value,
                "wids": JSON.stringify(wids),
                "employee_types": JSON.stringify(employee_types),
                "token": localStorage.getItem('token')
            }
            AXIOS.service(GetPlanningDates, "POST", data)
            .then(result => {
                if (result && result !== [] && result.status === 200 && result.data) {
                    setPlanData(result.data);
                } else if (result && result.status === 401){
                    props.CheckAuth();
                } else {
                    setPlanData([]);
                }
            })
            .catch(error => {
                console.log(error);
            })
        }
    }, [props.appliedFilterStatus, monthChangeStatus]);


    return (
            <div className="month-tab col-md-11 mx-auto bg-white px-4 pt-0 pb-4">
                <CalendarLayout planData={planData} changeMonth={changeMonth}></CalendarLayout>
            </div>
  
        
    );
}
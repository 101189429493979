import React, { useEffect }  from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GetTranslatedConstants } from "../translations/Translation";

export default function Authenticate() {

    let params = useParams();
    const navigate = useNavigate();

    // Saving token of the employee for the selected company and domain for authentication
    // After saving the details redirecting to weekly planning overview
    useEffect(() => {
        if (params){
            if (params.domain === 'app') {
                localStorage.setItem('domain', 'https://app.indii.be');
            } else if (params.domain === 'appb') {
                localStorage.setItem('domain', 'https://appb.indii.be');
            } else if (params.domain === 'hotfix-app') {
                localStorage.setItem('domain', 'https://hotfix-app.indii.infanion.com');
            } else if (params.domain === 'hotfix-appb') {
                localStorage.setItem('domain', 'https://hotfix-appb.indii.infanion.com');
            } else if (params.domain === 'hotfix-apps') {
                localStorage.setItem('domain', 'https://hotfix-apps.indii.infanion.com');
            } else if (params.domain === 'uat') {
                localStorage.setItem('domain', 'https://uat.indii.infanion.com');
            } else if (params.domain === 'test') {
                localStorage.setItem('domain', 'https://test.indii.infanion.com');
            } else if (params.domain === 'api') {
                localStorage.setItem('domain', 'https://api.indii.infanion.com');
            }   
            localStorage.removeItem('location');
            localStorage.removeItem('workstation');
            localStorage.removeItem('employee_type');
            localStorage.setItem('week_number', localStorage.getItem('current_week'));
            localStorage.setItem('date', localStorage.getItem('current_date'));
            localStorage.setItem('token', params.token);
            let translations = {'en':{}, 'nl':{}, 'fr':{}}
            localStorage.setItem('translations', JSON.stringify(translations))
            
            GetTranslatedConstants()
        }
        
        if (localStorage.getItem('domain')){
        if (params.lang == 'en'){
            navigate('/en')
            window.location.reload()
        }else{
            navigate('/nl')
            window.location.reload()
        }}
        // navigate('/'+ params.lang);}
    })
    
  return (
        <h2 className="text-center mt-2 font-success">Authenticating....</h2>
  );
}
import React, { useEffect, useState } from "react";
import DeleteIcon from '../../static/icons/Delete.svg';
import "../../static/common.css";
import FilterDropdown from "./FilterDropdown";
import { GetAvailability } from "../../routes/ApiEndPoints";
import { APICALL as AXIOS } from "../../services/AxiosServices";
import { t } from "../../translations/Translation";
import Select from 'react-select';
import RemarkIcon from "../../static/icons/exclamation-mark.png";



function TableRows({rowsData, dates, names, deleteTableRows, createPlan, workstation, planStatus, showAvailability, refreshStatus, popupState, savedWorkstations, searchedEmployees, availableDates, unAvailableDates, remarkDates, enableShifts}) {

    // const [selectedEmployee, selectEmployee] = useState([]);
    const [availableStatus, setAvailableStatus] = useState(false);
    let w = localStorage.getItem('week_number');
    let y = localStorage.getItem('year');
    const customStyles = {
        valueContainer: (provided, state) => ({
          ...provided,
          height: 25,
          minHeight: 25,
          overflow:"auto",
        }),
      };

    // useEffect(() => {
    //     // selectEmployee([])
    //     selectedEmployee.splice(0, selectedEmployee.length)
    //     let emps = JSON.parse(localStorage.getItem('saved_employees'))
    //     if (emps.length !== 0){
    //         for(let i=0;i<emps.length;i++){
    //             if (selectedEmployee.includes(emps[i][workstation]) === false && emps[i][workstation] !== undefined){
    //                 selectedEmployee.push(emps[i][workstation])
    //             }
    //         }
    //     }
    // }, [searchedEmployees, localStorage.getItem('saved_employees')])


    useEffect(() => {
        //Get the availability of given user
        let emps = JSON.parse(localStorage.getItem('saved_employees'))
        if (emps.length !== 0 && showAvailability) {
            emps.map((value, index) => {
                if (value && value[workstation] !== undefined) {
                    let data = {
                        'eid'  : value[workstation]['value'],
                        'lid'  : JSON.parse(localStorage.getItem('location')).value,
                        "token": localStorage.getItem('token'),
                    }
                
                    AXIOS.service(GetAvailability, 'POST', data)
                    .then(result => {
                        if (result && result.status === 200){ 
                            let available = {}  
                            let unavailable = {}
                            let remark = {}
                            available[value[workstation]['value']] = result.data.available
                            unavailable[value[workstation]['value']] = result.data.not_available
                            remark[value[workstation]['value']] = result.data.remarks
                            availableDates[index]= available;
                            unAvailableDates[index] = unavailable;
                            remarkDates[index] = remark;
                            setAvailableStatus(!availableStatus);
                        } else {
                            let available = {}  
                            let unavailable = {}
                            let remark = {}
                            available[value[workstation]['value']] = []
                            unavailable[value[workstation]['value']] = []
                            availableDates[index]= available;
                            unAvailableDates[index] = unavailable;
                            remarkDates[index]=remark
                            setAvailableStatus(!availableStatus);

                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
                }
            })
        }else{
            // setAvailableDates([]);
            // setUnAvailableDates([]);
        }
    }, [showAvailability, localStorage.getItem('saved_employees')])


    //Save searched employee datas
    const setSearchedEmployee = (emp, index) => {
        setAvailableStatus(!availableStatus);
        // selectedEmployee[index] = emp;
        // selectedEmployee.push(emp)
        if (!savedWorkstations.includes(workstation)){
            savedWorkstations[index] = workstation
            // savedWorkstations.push(workstation);
        }
        localStorage.setItem('saved_workstations', JSON.stringify(savedWorkstations));
        let data= {}
        data[workstation]= emp;
        let duplicate = false
        for (let i=0;i <= searchedEmployees.length-1; i++){
            if (searchedEmployees[i] && searchedEmployees[i][workstation] === emp){
               duplicate = true
            }
        }
        if (!duplicate){
            searchedEmployees[index]=data
            // searchedEmployees.push(data);
            localStorage.setItem('saved_employees', JSON.stringify(searchedEmployees));
        }        
    }
    

    return(
        rowsData.map((data, index)=>{
            if (workstation === data || ((workstation === data && JSON.parse(localStorage.getItem('saved_workstations')).includes(workstation)) || data === 0)){
                let employee = (JSON.parse(localStorage.getItem('saved_employees')))[index] ? ((JSON.parse(localStorage.getItem('saved_employees')))[index][workstation] ? (JSON.parse(localStorage.getItem('saved_employees')))[index][workstation]['value'] : '') : ''
                return(
                    <tr key={index} className="main-width row m-0">
                        <td className={enableShifts ? ("employee-width p-2 py-5 border-right py-auto "+ planStatus) : ("employee-width p-2 border-right py-auto "+ planStatus )}>
                            {names !== undefined &&
                                <Select
                                    placeholder= {t("SEARCH")}
                                    value={(JSON.parse(localStorage.getItem('saved_employees')))[index] ? (JSON.parse(localStorage.getItem('saved_employees')))[index][workstation] : ''}
                                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                    onChange={(e) => setSearchedEmployee(e, index)}
                                    isMulti={false}
                                    options={names}
                                    required={true}
                                    isDisabled={false}
                                    className="filter-scroll-emp"
                                    noOptionsMessage = {() => {t("NOT_FOUND")}}
                                    styles={customStyles}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                        ...theme.colors,
                                        primary25: '#d1f3e8',
                                        primary: '#61bfb5',
                                        },
                                    })}
                            />
                            }
                            {/* {names !== undefined && <FilterDropdown filterName="" options={names} placeHolder={t("SEARCH")} isMulti={false} selectedOption={JSON.parse(localStorage.getItem('saved_employees'))[index]  ? (JSON.parse(localStorage.getItem('saved_employees')))[index][workstation]: ''} setSelectedOption={(e) => setSearchedEmployee(e)} title={t("NAMES")} isDisabled={true}></FilterDropdown>} */}
                        </td>
                        <td className={availableDates.length !== 0 && availableDates[index] !== undefined && availableDates[index][employee] !== undefined && availableDates[index][employee].includes(dates[0]) && showAvailability ? ("header-box-size pointer border-right available "+planStatus) : (unAvailableDates.length !== 0 && unAvailableDates[index] !== undefined && unAvailableDates[index][employee] !== undefined && unAvailableDates[index][employee].includes(dates[0]) && showAvailability ?("header-box-size border-right pointer not-available "+planStatus) : ("header-box-size border-right "+planStatus))} onClick={()=>createPlan((employee?employee : 0), dates[0], 0, workstation)}>
                            {availableDates.length !== 0 && availableDates[index] !== undefined && availableDates[index][employee] !== undefined && availableDates[index][employee].includes(dates[0]) && showAvailability &&  remarkDates[index][employee][dates[0]] !== null && <img className="float-right planitem-icons" title={remarkDates[index][employee][dates[0]]} src={RemarkIcon}></img>}
                        </td>
                        <td className={availableDates.length !== 0 && availableDates[index] !== undefined && availableDates[index][employee] !== undefined && availableDates[index][employee].includes(dates[1]) && showAvailability ? ("header-box-size pointer border-right available "+planStatus) : (unAvailableDates.length !== 0 && unAvailableDates[index] !== undefined && unAvailableDates[index][employee] !== undefined && unAvailableDates[index][employee].includes(dates[1]) && showAvailability ?("header-box-size border-right pointer not-available "+planStatus) : ("header-box-size border-right "+planStatus))} onClick={()=>createPlan((employee?employee : 0), dates[1], 0, workstation)}>
                            {availableDates.length !== 0 && availableDates[index] !== undefined && availableDates[index][employee] !== undefined && availableDates[index][employee].includes(dates[1]) && showAvailability && remarkDates[index][employee][dates[1]] !== null && <img className="float-right planitem-icons" title={remarkDates[index][employee][dates[1]]} src={RemarkIcon}></img>}
                        </td>
                        <td className={availableDates.length !== 0 && availableDates[index] !== undefined && availableDates[index][employee] !== undefined && availableDates[index][employee].includes(dates[2]) && showAvailability ? ("header-box-size pointer border-right available "+planStatus) : (unAvailableDates.length !== 0 && unAvailableDates[index] !== undefined && unAvailableDates[index][employee] !== undefined && unAvailableDates[index][employee].includes(dates[2]) && showAvailability ?("header-box-size border-right pointer not-available "+planStatus) : ("header-box-size border-right "+planStatus))} onClick={()=>createPlan((employee?employee : 0), dates[2], 0, workstation)}>
                            {availableDates.length !== 0 && availableDates[index] !== undefined && availableDates[index][employee] !== undefined && availableDates[index][employee].includes(dates[2]) && showAvailability && remarkDates[index][employee][dates[2]] !== null && <img className="float-right planitem-icons" title={remarkDates[index][employee][dates[2]]} src={RemarkIcon}></img>}
                        </td>
                        <td className={availableDates.length !== 0 && availableDates[index] !== undefined && availableDates[index][employee] !== undefined && availableDates[index][employee].includes(dates[3]) && showAvailability ? ("header-box-size pointer border-right available "+planStatus) : (unAvailableDates.length !== 0 && unAvailableDates[index] !== undefined && unAvailableDates[index][employee] !== undefined && unAvailableDates[index][employee].includes(dates[3]) && showAvailability ?("header-box-size border-right pointer not-available "+planStatus) : ("header-box-size border-right "+planStatus))} onClick={()=>createPlan((employee?employee : 0), dates[3], 0, workstation)}>
                            {availableDates.length !== 0 && availableDates[index] !== undefined && availableDates[index][employee] !== undefined && availableDates[index][employee].includes(dates[3]) && showAvailability && remarkDates[index][employee][dates[3]] !== null && <img className="float-right planitem-icons" title={remarkDates[index][employee][dates[3]]} src={RemarkIcon}></img>}
                        </td>
                        <td className={availableDates.length !== 0 && availableDates[index] !== undefined && availableDates[index][employee] !== undefined && availableDates[index][employee].includes(dates[4]) && showAvailability ? ("header-box-size pointer border-right available "+planStatus) : (unAvailableDates.length !== 0 && unAvailableDates[index] !== undefined && unAvailableDates[index][employee] !== undefined && unAvailableDates[index][employee].includes(dates[4]) && showAvailability ?("header-box-size border-right pointer not-available "+planStatus) : ("header-box-size border-right "+planStatus))} onClick={()=>createPlan((employee?employee : 0), dates[4], 0, workstation)}>
                            {availableDates.length !== 0 && availableDates[index] !== undefined && availableDates[index][employee] !== undefined && availableDates[index][employee].includes(dates[4]) && showAvailability && remarkDates[index][employee][dates[4]] !== null && <img className="float-right planitem-icons" title={remarkDates[index][employee][dates[4]]} src={RemarkIcon}></img>}
                        </td>
                        <td className={availableDates.length !== 0 && availableDates[index] !== undefined && availableDates[index][employee] !== undefined && availableDates[index][employee].includes(dates[5]) && showAvailability ? ("header-box-size pointer border-right available "+planStatus) : (unAvailableDates.length !== 0 && unAvailableDates[index] !== undefined && unAvailableDates[index][employee] !== undefined && unAvailableDates[index][employee].includes(dates[5]) && showAvailability ?("header-box-size border-right pointer not-available "+planStatus) : ("header-box-size border-right "+planStatus))} onClick={()=>createPlan((employee?employee : 0), dates[5], 0, workstation)}>
                            {availableDates.length !== 0 && availableDates[index] !== undefined && availableDates[index][employee] !== undefined && availableDates[index][employee].includes(dates[5]) && showAvailability && remarkDates[index][employee][dates[5]] !== null && <img className="float-right planitem-icons" title={remarkDates[index][employee][dates[5]]} src={RemarkIcon}></img>}
                        </td>
                        <td className={availableDates.length !== 0 && availableDates[index] !== undefined && availableDates[index][employee] !== undefined && availableDates[index][employee].includes(dates[6]) && showAvailability ? ("header-box-size pointer border-right available "+planStatus) : (unAvailableDates.length !== 0 && unAvailableDates[index] !== undefined && unAvailableDates[index][employee] !== undefined && unAvailableDates[index][employee].includes(dates[6]) && showAvailability ?("header-box-size border-right pointer not-available "+planStatus) : ("header-box-size border-right "+planStatus))} onClick={()=>createPlan((employee?employee : 0), dates[6], 0, workstation)}>
                            {availableDates.length !== 0 && availableDates[index] !== undefined && availableDates[index][employee] !== undefined && availableDates[index][employee].includes(dates[6]) && showAvailability && remarkDates[index][employee][dates[6]] !== null && <img className="float-right planitem-icons" title={remarkDates[index][employee][dates[6]]} src={RemarkIcon}></img>}
                        </td>
                        <td className={"total-width border-right text-center "+ planStatus } onClick={()=>createPlan(employee, dates[7], 0, workstation)}></td>
                        <td className={"delete-width px-0 text-center align-self-center "+ planStatus } title={t('DELETE_ROW')}><img className="image2-icon pointer" src={DeleteIcon} onClick={()=>(deleteTableRows(index, 0, 0, ((JSON.parse(localStorage.getItem('saved_employees')))[index] ? (JSON.parse(localStorage.getItem('saved_employees')))[index][workstation]['value'] : 0)))} alt="delete icon"></img></td>

                    </tr>
                )
            }
        })
    )
}
export default TableRows;

import React from 'react';
import {Route, Routes} from 'react-router-dom';
import PlanningOverview from '../pages/PlanningOverview';
import Authenticate from '../utilities/Authenticate';

const langRegex = "(en|nl|fr)?";
const langPath =  `/:lang${langRegex}`;

const BaseRouter = () => (
    <Routes>
        {/* route starts here */}
        <Route exact path="/" element={<PlanningOverview />} />
        <Route exact path="/:lang" element={<PlanningOverview />} />
        <Route exact path='/:lang/:token/:domain' element={<Authenticate />}/>
    </Routes>
);

export default BaseRouter;
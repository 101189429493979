import React from "react";
import "../../static/common.css";

export default function NavItem(props) {

    const UpdateCurrentData = () => {
        if (props.tabHash === '#week'){
            localStorage.setItem('week_number', localStorage.getItem('current_week'))
        }else if(props.tabHash === '#day'){
            localStorage.setItem('date', localStorage.getItem('current_date'))
        }else{
            localStorage.setItem('year', new Date().getFullYear());
            localStorage.setItem('month', new Date().getMonth() + 1);
        }
    }

    return (
            <li className="nav-item col-md-2">
                <a className={props.isActive?"nav-link text-center active pointer":"nav-link text-center pointer"} href={props.tabHash} onClick={()=> UpdateCurrentData()}>{props.tabName}</a>
            </li>
        
    );
}